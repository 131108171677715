用户信息
<template>
  <div class="dtgl">
    <template>
      <div>
        <i class="el-icon-arrow-left"
           style="color:#5aabff; font-weight:800"></i>
        <el-button @click="back"
                   type="text"
                   size="medium"
                   style="font-weight:800">返回</el-button>
      </div>
      <!-- 进度条 -->
      <div>
        <el-steps :active="processStatus">
          <el-step title="未支付"
                   :description="processStatus==1?'待支付':''"></el-step>
          <el-step title="已支付"
                   :description="processStatus==2?'待预约/待采样':''"></el-step>
          <el-step title="已预约"
                   :description="processStatus==3?'待采样':''"></el-step>
          <el-step title="已采样"
                   :description="processStatus==4?'待上传报告':''"></el-step>
          <el-step title="已出报告"
                   :description="processStatus==5&&maxProgress==5?'待邮寄报告':''"></el-step>
          <el-step v-if="maxProgress==5"
                   title="已邮寄报告"></el-step>
        </el-steps>
      </div>
      <!-- 组件 -->
      <el-tabs v-model="activeName">
        <el-tab-pane label="订单详情"
                     v-if="authAttr"
                     name="1">
          <template>
            <Orders @watchChild="getOrderProcessStatus"
                    ref="Orders" />
          </template>
        </el-tab-pane>
        <el-tab-pane label="样本详情"
                     v-if="sampleyAuth"
                     name="2">
          <template>
            <Sample @watchChild="getOrderProcessStatus"
                    :processStatus='processStatus' />
          </template>
        </el-tab-pane>
        <el-tab-pane label="预约详情"
                     v-if="ordersAuth"
                     name="3">
          <template>
            <Booking @watchChild="getOrderProcessStatus" />
          </template>
        </el-tab-pane>
        <el-tab-pane label="返利详情"
                     v-if="rebateyAuth"
                     name="4">
          <template>
            <Rebatey @watchChild="getOrderProcessStatus" />
          </template>
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>

<script>
import Orders from '@/views/management-center/particulars/orders.vue'
import Sample from '@/views/management-center/particulars/sample.vue'
import Booking from '@/views/management-center/particulars/booking.vue'
import Rebatey from '@/views/management-center/particulars/rebatey.vue'
import axios from 'axios'
export default {
  components: { Orders, Sample, Booking, Rebatey },
  data() {
    return {
      activeName: '1',
      authAttr: '',
      particularsType: '',

      orderId: '',
      pageNum: '',
      processStatus: 1,
      ordersAuth: '',
      rebateyAuth: '',
      sampleyAuth: '',
      maxProgress: ''
    }
  },
  created() {
    this.pageNum = this.$route.query.pageNum
    this.orderId = this.$route.query.orderId
    this.particularsType = this.$route.query.particularsType
    this.activeName = this.$route.query.particularsType

    this.getAuthAttr()
    this.getOrderProcessStatus()
  },
  methods: {
    // 获取权限
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-3')
      var ordersAuth = window.sessionStorage.getItem('auth-7')
      var sampleyAuth = window.sessionStorage.getItem('auth-6')
      var rebateyAuth = window.sessionStorage.getItem('auth-14')
      this.authAttr = authAttr
      this.ordersAuth = ordersAuth
      this.sampleyAuth = sampleyAuth
      this.rebateyAuth = rebateyAuth
    },
    // 返回
    back() {
      this.$router.go(-1)
      // if (!this.pageNum) {
      //   this.$router.back()
      // } else {
      //   var pathArr = ['/order', '/sample', '/appointment']
      //   var path = pathArr[this.particularsType - 1]
      //   this.$router.push({ path: path, query: { pageNum: this.pageNum } })
      // }
    },
    // 发送异步请求
    getOrderProcessStatus() {
      var that = this
      this.$http
        .get(`/admin/order/getOrderProcessStatus?orderId=${this.orderId}`)
        .then(function (res) {
          that.processStatus = Number(res.data.data.progress + 1)
          that.maxProgress = res.data.data.maxProgress
          that.$refs.Orders.getOrderInfoById()
        })
        .catch(function (error) {})
    }
  }
}
</script>
<style lang='scss' scoped>
.dtgl {
  .order {
    margin: 20px 0px;
    font-size: 20px;
  }
  font-size: 14px;
  overflow-x: hidden;
  .search-bar {
    padding: 14px 0px;
    min-width: 990px;
  }

  .btns-curd {
    padding: 14px 0px;
  }

  .pagination {
    padding-top: 10px;
    text-align: right;
  }
  ::v-deep.el-step__description.is-finish {
    color: orangered;
  }
}
</style>