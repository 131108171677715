<!-- 首页 -->
<template>
  <div id="app"
       @click="tagMenuShow=false">
    <el-container class="container">
      <!-- 左 -->
      <!-- 左侧图标 -->
      <el-aside :width="isCollapse?'65px':'200px'"
                class="aside">
        <div class="logo">
          <img src="../assets/images/logo.png"
               alt="">
          <h1 v-show="!isCollapse">高美产品后台</h1>
        </div>
        <!-- 左侧菜单栏 -->
        <el-menu :default-active="currentPath"
                 class="menu-left"
                 :collapse="isCollapse"
                 background-color="#304156"
                 text-color="#fff"
                 active-text-color="#ffd04b"
                 :unique-opened="true"
                 router>
          <template v-for="item in menuList">
            <el-submenu :index="item.path"
                        :key="item.path"
                        v-if="item.children&&item.visiable">
              <!-- 一级菜单里面包含二级菜单 -->
              <template slot="title">
                <i :class="'iconfont icon-'+item.meta.icon"></i>
                <span> {{ item.meta.title }}</span>
              </template>
              <!-- 二级菜单 -->
              <template v-for="menu in item.children">
                <el-menu-item class="second"
                              :index="menu.path"
                              :key="menu.path"
                              v-if="!menu.hidden&&menu.visiable">
                  <i :class="'iconfont icon-'+menu.meta.icon"></i>
                  {{ menu.meta.title }}
                </el-menu-item>
              </template>
            </el-submenu>
            <!-- 一级菜单 -->
            <el-menu-item :index="item.path"
                          :key="item.path"
                          v-show="item.visiable"
                          v-if="!item.children&&!item.hidden&&item.visiable">
              <i :class="'iconfont icon-'+item.meta.icon"></i>
              <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>

      </el-aside>
      <!-- 右 -->
      <el-main>
        <!-- 顶部导航 -->
        <el-row type="flex"
                justify="space-between"
                align="middle">
          <!-- 折叠 -->
          <!-- 导航栏 -->
          <el-col :xs="7"
                  :sm="10"
                  :md="13"
                  :lg="16">
            <i v-show="!isCollapse"
               class="iconfont icon-zhedie1"
               @click="isCollapse=true"></i>
            <i v-show="isCollapse"
               class="iconfont icon-zhedie2"
               @click="isCollapse=false"></i>
          </el-col>
          <!-- 头像 -->
          <el-col :xs="6"
                  :sm="5"
                  :md="4"
                  :lg="3"
                  style="text-align: right;"><span>{{ this.txname }}</span></el-col>
          <el-col :xs="6"
                  :sm="5"
                  :md="4"
                  :lg="3">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <img class="avatar"
                     :src="avatarUrl"
                     alt="" />
                <i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="clearfix"
                                  @click.native="tc">
                  退出登录
                  <el-badge class="mark" />
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>

        <!-- 展示区域 -->
        <div class="zs">
          <transition name="fade"
                      mode="out-in">
            <router-view :key="time"></router-view>
          </transition>
        </div>
      </el-main>
    </el-container>
  </div>
</template>


<script>
import set from '../views/management-center/set/set.vue'
import sample from '../views/management-center/sample/sample.vue'
import sampleCollection from '../views/management-center/sample/sampleCollection.vue'
import order from '../views/management-center/order/order.vue'
import particulars from '@/views/management-center/particulars/index.vue'
import appointment from '../views/management-center/appointment/appointment.vue'
import appointmentParticulars from './management-center/userInfo/userAppointmentParticulars.vue'
import user from '../views/management-center/user/user.vue'
import userInfo from './management-center/userInfo/userInfo.vue'
import product from '../views/management-center/product/product.vue'
import productParticulars from '../views/management-center/product/productParticulars.vue'
import promotionCode from '../views/management-center/promotionCode/promotionCode.vue'
import personnel from '../views/management-center/admin/personnel.vue'
import role from '../views/management-center/admin/role.vue'
import auth from '../views/management-center/admin/auth.vue'
import adminAuth from '../views/management-center/admin/adminAuth.vue'
import record from '../views/management-center/recommend/record.vue'
import informationCollection from '../views/management-center/informationCollection/informationCollection.vue'
import vip from '../views/management-center/vip/vip.vue'
import agency from '../views/management-center/agency/agency.vue'
import withdraw from '../views/management-center/withdraw/withdraw.vue'
import activity from '../views/management-center/activity/activity.vue'
import detailedActivity from '../views/management-center/activity/detailedActivity.vue'

// 销售
import sellerAppointment from '../views/management-sales/appointment/appointment.vue'
import seller from '../views/management-sales/seller/seller.vue'
import userOrder from '../views/management-sales/userOrder/userOrder.vue'
import cancelTheReservation from '../views/management-sales/appointment/cancelTheReservation.vue'

export default {
  data() {
    return {
      //管理员信息
      adminxx: {
        type: '1'
      },
      isCollapse: true,
      //用户名字
      txname: '',
      //用户头像
      avatarUrl: require('@/assets/images/avatar-default.svg'),
      // 个人信息
      userInfo: [],
      // 全部数据
      routerList: [],
      // 左侧菜单栏
      menuList: [
        {
          name: '/index',
          path: '/index',
          hidden: false,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER', 'ROLE_SELLER'],
          component: 'index',
          visiable: false,
          meta: {
            title: '首页',
            icon: 'shouye',
            noCache: false
          }
        },
        {
          name: '/admin',
          path: '/admin',
          hidden: false,
          redirect: 'noRedirect',
          component: 'Layout',
          alwaysShow: true,
          visiable: false,
          authType: 5,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          meta: {
            title: '管理员模块',
            icon: 'manager',
            noCache: false
          },
          children: [
            {
              name: '/admin/personnel',
              path: '/admin/personnel',
              hidden: false,
              component: personnel,
              meta: {
                title: '人员管理',
                noCache: false,
                icon: 'renyuanguanli'
              },
              visiable: false,
              roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER']
            },
            {
              name: '/admin/role',
              path: '/admin/role',
              hidden: false,
              component: role,
              meta: {
                title: '角色管理',
                noCache: false,
                icon: 'jiaoseguanli'
              },
              roles: ['ROLE_ROOT'],
              visiable: false
            },
            {
              name: '/admin/auth',
              path: '/admin/auth',
              hidden: false,
              component: auth,
              meta: {
                title: '权限管理',
                noCache: false,
                icon: 'quanxianguanli'
              },
              roles: ['ROLE_ROOT'],
              visiable: false
            },
            {
              name: '/admin/adminAuth',
              path: '/admin/adminAuth',
              hidden: true,
              component: adminAuth,
              meta: {
                title: '个人权限配置',
                noCache: false
              },
              roles: ['ROLE_ROOT'],
              visiable: false
            }
          ]
        },
        {
          name: '/user',
          path: '/user',
          hidden: false,
          authType: 2,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: user,
          visiable: false,
          meta: {
            title: '用户管理',
            icon: 'xitongguanli_yonghuguanli',
            noCache: false
          }
        },
        {
          name: '/userInfo',
          path: '/userInfo',
          hidden: true,
          authType: 2,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: user,
          visiable: false,
          meta: {
            title: '用户详情',
            icon: 'build',
            noCache: false
          }
        },
        {
          name: '/product',
          path: '/product',
          visiable: false,
          hidden: false,
          authType: 4,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: product,
          meta: {
            title: '产品管理',
            icon: 'chanpinguanli1',
            noCache: false
          }
        },
        {
          name: '/productParticulars',
          visiable: false,
          path: '/productParticulars',
          hidden: true,
          authType: 4,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: productParticulars,
          meta: {
            title: '产品详情',
            icon: 'checkbox',
            noCache: false
          }
        },
        {
          name: '/order',
          path: '/order',
          hidden: false,
          redirect: 'noRedirect',
          component: 'ordermodule',
          alwaysShow: true,
          visiable: false,
          authType: 3,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          meta: {
            title: '订单管理',
            noCache: false,
            icon: 'dingdanguanli'
          }
        },
        {
          name: '/sample',
          visiable: false,
          path: '/sample',
          hidden: false,
          authType: 6,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: sample,
          meta: {
            title: '样本管理',
            noCache: false,
            icon: 'yangbenguanli'
          }
        },
        {
          name: '/agent',
          path: '/agent',
          hidden: false,
          redirect: 'noRedirect',
          component: 'Layout',
          alwaysShow: true,
          visiable: false,
          authType: 14,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          meta: {
            title: '代理模块',
            icon: 'dailishang1',
            noCache: false
          },
          children: [
            {
              name: '/agency',
              visiable: false,
              path: '/agency',
              hidden: false,
              roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
              component: agency,
              meta: {
                title: '代理管理',
                noCache: false,
                icon: 'dailishang'
              }
            },
            {
              name: '/withdraw',
              visiable: false,
              path: '/withdraw',
              hidden: false,
              roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
              component: withdraw,
              meta: {
                title: '提现管理',
                noCache: false,
                icon: 'tixian'
              }
            }
          ]
        },

        {
          name: '/appointment',
          visiable: false,
          path: '/appointment',
          hidden: false,
          authType: 7,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: appointment,
          meta: {
            title: '预约管理',
            noCache: false,
            icon: 'yuyueguanli'
          }
        },
        {
          name: '/recommend/record',
          path: '/recommend/record',
          hidden: false,
          component: 'record',
          visiable: false,
          authType: 11,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          meta: {
            title: '推荐记录管理',
            noCache: false,
            icon: 'tuijianguanli'
          }
        },
        {
          name: '/promotionCode',
          visiable: false,
          path: '/promotionCode',
          hidden: false,
          authType: 10,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: promotionCode,
          meta: {
            title: '优惠码管理',
            noCache: false,
            icon: 'youhuimaguanli'
          }
        },
        {
          name: '/activity',
          visiable: false,
          path: '/activity',
          hidden: false,
          authType: 15,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: activity,
          meta: {
            title: '活动管理',
            noCache: false,
            icon: 'liwuhuodong'
          }
        },
        {
          name: '/vip',
          visiable: false,
          path: '/vip',
          hidden: false,
          authType: 13,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: vip,
          meta: {
            title: 'VIP卡管理',
            noCache: false,
            icon: 'vip'
          }
        },
        {
          name: '/informationCollection',
          visiable: false,
          path: '/informationCollection',
          hidden: false,
          authType: 12,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: informationCollection,
          meta: {
            title: '信息采集管理',
            noCache: false,
            icon: 'xinxicaijiguanli'
          }
        },
        {
          name: '/sampleCollection',
          visiable: false,
          path: '/sampleCollection',
          hidden: false,
          authType: 6,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: sampleCollection,
          meta: {
            title: '样本采集',
            noCache: false,
            icon: 'caiji'
          }
        },
        {
          name: '/set',
          path: '/set',
          hidden: false,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER', 'ROLE_SELLER'],
          component: set,
          visiable: false,
          meta: {
            title: '设置',
            noCache: false,
            icon: 'gerenxinxiguanli'
          }
        },
        {
          name: '/particulars',
          visiable: false,
          path: '/particulars',
          hidden: true,
          authType: 3,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: particulars
        },

        {
          name: '/seller',
          path: '/seller',
          roles: ['ROLE_SELLER'],
          hidden: false,
          component: seller,
          meta: {
            title: '我的用户',
            noCache: false
          }
        },
        {
          name: '/userOrder',
          path: '/userOrder',
          roles: ['ROLE_SELLER'],
          hidden: false,
          component: userOrder,
          meta: {
            title: '用户下单',
            noCache: false
          }
        },
        {
          name: '/sellerAppointment',
          path: '/sellerAppointment',
          roles: ['ROLE_SELLER'],
          hidden: false,
          component: sellerAppointment,
          meta: {
            title: '客户预约',
            noCache: false
          }
        },
        {
          name: '/appointment',
          visiable: false,
          path: '/appointment',
          hidden: true,
          authType: 7,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: appointment,
          meta: {
            title: '预约详情',
            noCache: false
          }
        },
        {
          name: '/detailedActivity',
          visiable: false,
          path: '/detailedActivity',
          hidden: true,
          authType: 15,
          roles: ['ROLE_ROOT', 'ROLE_ADMIN_PRODUCT', 'ROLE_ADMIN_SELLER'],
          component: detailedActivity,
          meta: {
            title: '活动详情',
            noCache: false
          }
        }
      ],
      // 当前菜单全部数据的下标
      menuActiveIndex: 0,
      activeName: 'second',
      tagList: [{ title: '首页', path: '/index', status: true }],
      currentPath: '/',
      tagMenuShow: false,
      x: 0,
      y: 0,
      time: Date.now(),
      icon: true
    }
  },
  mounted() {
    if (this.$equipment() != null) {
      this.isCollapse = true
    } else {
      this.isCollapse = false
      this.icon = false
    }
    //如果currentTagList有缓存，则初始化tagList
    const currentTagList = sessionStorage.getItem('currentTagList')
    if (currentTagList) this.tagList = JSON.parse(currentTagList)
    //初始化当前的路径currentPath
    this.currentPath = this.$route.path
    this.tx()
    // this.getRouters(),
    this.setRoleShow()
    this.parseAuth()
  },

  watch: {
    // 监听路由的改变
    $route(route) {
      console.log(route)
      //更新currentPath当前路径
      this.currentPath = route.path
      //先把所有状态改变false
      this.tagList.forEach((el) => (el.status = false))
      //当前路由
      const { path, meta } = route
      //判断当前路由路径是否存在tagList数组中
      let tag = this.tagList.find((el) => el.path == path)
      if (tag) {
        tag.status = true
      } else {
        //如果不存在则添加到tagList数组中
        tag = { title: meta.title, path: path, status: true }
        this.tagList.push(tag)
      }
      //把当前的路径存放到本地中
      if (tag.path === '/') {
        sessionStorage.setItem('currentTagList', JSON.stringify([tag]))
      }
      if (tag.path !== '/') {
        sessionStorage.setItem('currentTagList', JSON.stringify([{ title: '首页', path: '/index', status: true }, tag]))
      }
    }
  },
  methods: {
    parseAuth() {
      var roles = window.sessionStorage.getItem('role')
      if (roles == 'ROLE_ROOT') {
        for (var i = 1; i <= 15; i++) {
          window.sessionStorage.setItem('auth-' + i, 3)
        }
        return
      }
      var authList = window.sessionStorage.getItem('auth')
      var auths = JSON.parse(authList)
      var authL = []
      auths.forEach((auth) => {
        authL.push(auth.type)
        window.sessionStorage.setItem('auth-' + auth.type, auth.attribute)
      })
      this.menuList.forEach((element) => {
        if (element.authType == undefined) {
          return
        }
        if (!authL.includes(element.authType)) {
          element.visiable = false
        }
      })
    },
    setRoleShow() {
      var roles = window.sessionStorage.getItem('role')
      this.menuList.forEach((element) => {
        if (element.roles.includes(roles)) {
          element.visiable = true
        }
        if (element.children != undefined && element.children.length > 0) {
          element.children.forEach((item) => {
            if (item.roles.includes(roles)) {
              item.visiable = true
            } else {
              item.visiable = false
            }
          })
        }
      })
    },
    tc() {
      window.sessionStorage.removeItem('role')
      window.sessionStorage.removeItem('token')
      window.sessionStorage.removeItem('currentTagList')
      for (var i = 1; i <= 14; i++) {
        window.sessionStorage.removeItem('auth-' + i)
      }
      window.sessionStorage.removeItem('auth')
      window.sessionStorage.removeItem('tokenStartTime')
      this.$router.push('/')
    },
    //头像信息
    async tx() {
      const { data: res } = await this.$http.get('/admin/admin/getAdminInfo')
      this.txname = res.data.username
      if (res.data.avatar !== null && res.data.avatar !== undefined && res.data.avatar !== '') {
        this.avatarUrl = this.$url + res.data.avatar
      }
    },
    //处理tag的滚动
    tagScroll(event) {
      const wheelDelta = event.wheelDelta
      const wrap = this.$refs.scroll.wrap
      //在当前滚动条的位置基础上做移动
      wrap.scrollLeft = wrap.scrollLeft + wheelDelta / 4
    }

    // 个人信息
    // async getInfo() {
    //   const { data: res } = await this.$http.get('/getInfo')
    //   if (res.code != 200) return this.$message.error(res.msg)
    //   this.userInfo = res.user
    //   // console.log(res)
    // },
    // // 请求页面路由信息
    // async getRouters() {
    //   const { data: res } = await this.$http.get('/getRouters')
    //   if (res.code != 200) return this.$message.error(res.msg)
    //   this.routerList = res.data

    //   this.menuList = this.routerList[this.menuActiveIndex].children
    // },
    // handleClick(name) {
    //   this.menuActiveIndex = name.index
    //   this.getRouters()
    // }
  }
}
</script>

<style lang='scss' scoped>
// 设置切换动画
.fade-level-active,
.fade-enter-active {
  transition: all 0.5s;
}

.avatar {
  width: 30px;
  height: 30px;
}

.fade-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-level-to {
  opacity: 0;
  transform: translateX(30px);
}

.container {
  width: 100vw;
  height: 100vh;
}

.aside {
  background: #304156;
}

// 左边logo
.logo {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  img {
    width: 32px;
    width: 32px;
    margin-right: 10px;
  }
}

.menu-left {
  border-right: none;

  .second {
    background: #1f2d3d !important;
    min-width: 0;
  }

  .second:hover {
    background: #001528 !important;
  }
}

// 右边折叠
.hidn-show {
  span {
    width: 100%;
    font-size: 20px;
  }
}

// 头像
.el-dropdown-link {
  margin-left: 25px;
}

// 顶部可关闭标签页
.tagbar {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 /12%), 0 0 3px 0 rgb(0 0 0 /4%);

  .list {
    padding-left: 14px;
    height: 34px;
    line-height: 34px;

    .item {
      margin-right: 5px;
    }

    .active {
      background: #1890ff;
      color: #fff;

      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #fff;
        border-radius: 50%;
      }
    }
  }
}

// 展示区域
.zs {
  margin-top: 10px;
}

.iconfont {
  margin-right: 5px;
  font-size: 23px;
}

//tag操作列表
.contextmenu {
  position: absolute;
  background: #fff;
  width: 88px;
  box-shadow: 2px 2px 3px 0 rgb(0 0 0 / 30%);
  font-size: 12px;
  color: #999;
  text-align: center;
  z-index: 999;

  li {
    padding: 10px;

    &:hover {
      background: #eeeeee;
    }
  }
}
</style>