<!-- 用户信息 -->
<template>
  <div>
    <div>
      <i class="el-icon-arrow-left"
         style="color:#5aabff; font-weight:800"></i>
      <el-button @click="back"
                 type="text"
                 size="small"
                 style="font-weight:800">返回</el-button>
    </div>
    <div class="content">
      <div class="content_left">
        <div>活动名称:</div>
        <div class="name">{{activityRes.name}}</div>
      </div>
      <div class="content_centre">
        <div>活动时间:</div>
        <div class="time">{{activityRes.startTime}}</div>
        <div style="margin-left:10px">到</div>
        <div class="time">{{activityRes.endTime}}</div>
      </div>
      <div v-if="productAttr>1&&activityAuth>1&&agencyAuth>1">
        <el-button type="success"
                   @click="openAddDialogVisible()">新增活动</el-button>
      </div>
    </div>
    <!-- 添加活动详情-->
    <template>
      <el-dialog title="新增活动详情"
                 :visible.sync="addDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="addActivityDiscountList"
                   ref="addActivityDiscountList"
                   :rules="rules"
                   label-width="100px">
            <el-form-item label="活动产品"
                          prop="productId">
              <el-select v-model="addActivityDiscountList.productId"
                         placeholder="请选择产品">
                <el-option v-for="item in allProductList"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id"></el-option>

              </el-select>
            </el-form-item>
            <el-form-item label="是否全部代理">
              <el-switch v-model="isall"></el-switch>
            </el-form-item>
            <el-form-item label="代理用户"
                          prop="userId"
                          v-if="!isall">
              <el-select v-model="addActivityDiscountList.userId"
                         clearable
                         filterable
                         @click.native="userJumpChange"
                         :filter-method="(value) => getAddableUser(value, 4)"
                         placeholder="请输入用户手机号或姓名">
                <el-option v-for="item in options"
                           :key="item.id"
                           :label='item.label'
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="优惠类型">
              <el-radio-group v-model="discountType">
                <el-radio label="1">打折</el-radio>
                <el-radio label="2">减扣</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="discountType==1"
                          label="折扣"
                          prop="discount">
              <el-input v-model="addActivityDiscountList.discount"
                        style="width:80px"></el-input>
              <span style=" margin-left:6px">折</span>
            </el-form-item>
            <el-form-item v-if="discountType==2"
                          label="减扣"
                          prop="concessionalPrice">
              <el-input v-model="addActivityDiscountList.concessionalPrice"
                        style="width:80px"></el-input>
              <span style=" margin-left:6px">元</span>
            </el-form-item>
            <el-form-item label="产品图片"
                          prop="image">
              <el-upload action="api/admin/activity/uploadTempFile"
                         ref="productImg"
                         :headers="headers"
                         :limit="1"
                         :auto-upload="true"
                         list-type="picture-card"
                         :on-remove="handleRemoves"
                         :on-success="uploadFileSuccess">
                <i class="el-icon-plus"></i>
              </el-upload>
              <span style="color:coral">请上传图片类型为jpg或png格式且大小不高于2M的图片</span>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="resetForm('addActivityDiscountList')">取 消</el-button>
          <el-button type="success"
                     @click="submintAddActivityDiscount('addActivityDiscountList')">提交</el-button>
        </span>
      </el-dialog>
    </template>
    <el-card class="box-card">
      <!-- (CURD)操作按钮 -->
      <!-- 表格区域 -->
      <el-table :data="activityDiscountInfoResList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="productName"
                         align="center"
                         label="产品名称">
        </el-table-column>
        <el-table-column prop="startTime"
                         align="center"
                         label="图片">
          <template slot-scope="scope">
            <el-image v-if="scope.row.image"
                      style="width: 100px; height: 100px"
                      :preview-src-list="srcList"
                      :src="$url+scope.row.image">
              <div slot="error"
                   class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="userShortInfoRes"
                         align="center"
                         label="代理人员">
          <template slot-scope="scope">
            {{textFilter2(scope.row.userShortInfoRes.phone,scope.row.userShortInfoRes.name)}}
          </template>
        </el-table-column>
        <el-table-column prop="endTime"
                         align="center"
                         label="折扣">
          <template slot-scope="scope">
            <div v-if="scope.row.concessionalPrice==0"> {{discountEcho(scope.row.discount)}}折</div>
            <div v-else> 减扣{{scope.row.concessionalPrice}}</div>
          </template>

        </el-table-column>

        <el-table-column label="操作"
                         v-if="activityAuth>1">
          <template v-slot="{row}">
            <el-button-group>
              <el-button @click="delActivityDiscount(row.id)"
                         type="danger"
                         size="mini"
                         icon="el-icon-delete">删除</el-button>
            </el-button-group>

          </template>
        </el-table-column>
      </el-table>
    </el-card>

  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js'
export default {
  data() {
    var validateDiscount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('折扣不能为空'))
      } else if (value >= 10) {
        callback(new Error('折扣值不能大于等于10'))
      } else if (value < 0) {
        callback(new Error('折扣值不能小于0'))
      } else {
        callback()
      }
    }
    return {
      activityId: '',
      activityDiscountInfoResList: [],
      activityRes: {},
      addDialogVisible: false,
      addActivityDiscountList: {
        userId: '',
        productId: '',
        activityId: '',
        discount: '',
        concessionalPrice: '',
        image: ''
      },
      isall: true,
      discountType: '1',
      rules: {
        productId: [{ required: true, message: '请选择活动产品', trigger: 'change' }],
        userId: [{ required: true, message: '请选择代理用户', trigger: 'change' }],
        discount: [{ validator: validateDiscount, trigger: 'blur' }],
        concessionalPrice: [{ required: true, message: '请输入减扣', trigger: 'blur' }],
        image: [{ required: true, message: '请上传活动图片', trigger: 'change' }]
      },
      headers: {
        token: sessionStorage.getItem('token')
      },
      allProductList: [],
      srcList: [],
      options: [],
      activityAuth: '',
      productAttr: '',
      agencyAuth: ''
    }
  },
  created() {
    this.activityId = this.$route.query.activityId
    this.getActivityInfo()
    this.getAuthAttr()
  },
  methods: {
    textFilter2(phone, name) {
      if (name == '' || name == null) {
        return phone
      } else if ((phone == null || phone == '') && name != '' && name != null) {
        return name
      }
      return phone + '(' + name + ')'
    },
    userJumpChange() {
      this.options = []
    },
    // 获取代理可添加人员
    async getAddableUser(keyword, type) {
      const { data: res } = await this.$http.get('/admin/proxy/getAddableUser', { params: { keyword: keyword, type: type } })
      if (res.code == 200) {
        this.options = []
        res.data.forEach((element) => {
          var obj = {}
          obj['label'] = `${element.phone}${element.name == null ? '' : '(' + element.name + ')'}`
          obj['id'] = element.id
          this.options.push(obj)
        })
      }
    },
    uploadFileSuccess(response, file) {
      console.log(response)
      if (response.code == 200) {
        this.$refs.addActivityDiscountList.clearValidate('image')
        this.addActivityDiscountList.image = response.data
      } else {
        this.$refs.productImg.clearFiles()
        this.$message.error(response.msg)
      }
    },

    handleRemoves(file) {
      this.addActivityDiscountList.image = ''
    },
    submintAddActivityDiscount(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.addActivityDiscountList.discount = this.discountTransition(this.addActivityDiscountList.discount)
          const { data: res } = await this.$http.post('/admin/activity/addActivityDiscount', this.addActivityDiscountList)
          if (res.code == 200) {
            this.$message.success('新增活动详情成功')
            this.getActivityInfo()
          } else {
            this.$message.error(res.msg)
          }
          this.$refs.productImg.clearFiles()
          this.$refs[formName].resetFields()
          this.addDialogVisible = false
          this.addActivityDiscountList.concessionalPrice = ''
        }
      })
    },
    discountTransition(num) {
      var num1 = BigNumber(num)
      var a = BigNumber(1)
      var b = BigNumber(0.1)
      return a.minus(num1.multipliedBy(b)).toNumber()
    },
    discountEcho(num) {
      var num1 = BigNumber(num)
      var a = BigNumber(1)
      var b = BigNumber(10)
      return a.minus(num).multipliedBy(b).toNumber()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.addDialogVisible = false
    },
    openAddDialogVisible() {
      this.addDialogVisible = true
      this.addActivityDiscountList.activityId = this.activityId
      this.getAllProductList()
    },
    async getAllProductList() {
      const { data: res } = await this.$http.get(`/admin/product/getAllProductList`)
      if (res.code == 200) {
        this.allProductList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取权限
    getAuthAttr() {
      var productAttr = window.sessionStorage.getItem('auth-4')
      var agencyAuth = window.sessionStorage.getItem('auth-14')
      var activityAuth = window.sessionStorage.getItem('auth-15')
      this.productAttr = productAttr
      this.agencyAuth = agencyAuth
      this.activityAuth = activityAuth
    },
    // 详情
    async getActivityInfo() {
      const { data: res } = await this.$http.get(`/admin/activity/getActivityInfo?activityId=${this.activityId}`)
      if (res.code == 200) {
        this.activityDiscountInfoResList = res.data.activityDiscountInfoResList
        this.activityRes = res.data.activityRes
        this.srcList = []
        res.data.activityDiscountInfoResList.forEach((e) => {
          if (e.image != null && e.image != '') {
            this.srcList.push(this.$url + e.image)
          }
        })
      } else {
        this.$message.error(res.msg)
      }
    },

    async delActivityDiscount(id) {
      const msg = await this.$confirm('确定要删除该产品活动吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/admin/activity/delActivityDiscount?activityDiscountId=${id}`)
        if (res.code != 200) {
          return this.$message.error(res.msg)
        } else {
          this.$message.success('删除成功')
        }
        //删除成功了，刷新页面
        this.getActivityInfo()
      }
    },
    // 返回用户列表
    back() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='scss' scoped>
.content {
  height: 40px;
  display: flex;
  justify-content: space-between;
  .content_left {
    display: flex;
    line-height: 40px;
    .name {
      margin-left: 10px;
      font-weight: 600;
    }
  }
  .content_centre {
    display: flex;
    line-height: 40px;
    .time {
      margin-left: 10px;
    }
  }
  .content_right {
    display: flex;
  }
}
</style>