<!-- 预约模块 -->
<template>
  <div>
    <!-- 头部搜索框 -->
    <el-form :model="queryParams"
             ref="queryParams"
             size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label="用户名/手机号">
        <el-input size="medium"
                  v-model="queryParams.keyword"
                  clearable
                  placeholder="请输入用户名或手机号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>

      <el-form-item label="状态"
                    label-width="68px">
        <el-select v-model="queryParams.status"
                   placeholder="">
          <el-option label="全部"
                     value=""></el-option>
          <el-option label="已取消"
                     value="0"></el-option>
          <el-option label="已预约"
                     value="1"></el-option>
          <el-option label="已确认"
                     value="2"></el-option>
          <el-option label="已完成"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预约号"
                    label-width="68px">
        <el-input size="medium"
                  v-model="queryParams.id"
                  clearable
                  placeholder="请输入预约号"
                  @keyup.enter.native="inquire"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="inquire">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="tableData"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="userPhone"
                           align="center"
                           label="手机号">
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goOrderInfo(scope.row.userPhone)">{{scope.row.userPhone}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="name"
                           align="center"
                           label="姓名"></el-table-column>
          <el-table-column prop="id"
                           align="center"
                           label="预约号"></el-table-column>
          <el-table-column prop="status"
                           align="center"
                           :formatter="zt"
                           label="状态"></el-table-column>
          <el-table-column prop="doorTime"
                           align="center"
                           :formatter="dateFormat"
                           label="上门时间"></el-table-column>
          <el-table-column prop="phone"
                           align="center"
                           label="联系电话"></el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           label="创建时间"></el-table-column>
          <el-table-column label="操作"
                           align="center">
            <template v-slot="{row}">
              <el-button-group>
                <el-button type="text"
                           size="mini"
                           icon="el-icon-tickets"
                           @click="delHandler(row.orderId)">详情</el-button>
              </el-button-group>

            </template>

          </el-table-column>
        </el-table>
      </template>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getAppointmentList" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        status: '',
        id: ''
      },
      tableData: [],
      total: 0,
      authAttr: ''
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getAppointmentList()
    this.getAuthAttr()
  },

  methods: {
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getAppointmentList()
    },
    dateFormat(row, column) {
      var dT = new Date(row.doorTime) //row 表示一行数据, dateTime 表示要格式化的字段名称
      return dT.getFullYear() + '-' + (dT.getMonth() + 1) + '-' + dT.getDate() + ' ' + dT.getHours() + ':' + dT.getMinutes() + ':' + dT.getSeconds()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-7')
      this.authAttr = authAttr
    },
    // 查询表格数据
    async getAppointmentList() {
      const { data: res } = await this.$http.get('/admin/appointment/getAppointmentList', { params: this.queryParams })
      if (res.code != 200) return this.$msgErr(res.msg)
      this.tableData = res.data.list
      this.total = res.data.total
    },
    zt(row) {
      if (row.status == 0) {
        return '已取消'
      } else if (row.status == 1) {
        return '已预约'
      } else if (row.status == 2) {
        return '已确认'
      } else if (row.status == 3) {
        return '已完成'
      }
    },
    typeFormat(row) {
      if (row.type == 1) {
        return '血液'
      } else if (row.type == 2) {
        return '尿液'
      } else if (row.type == 3) {
        return '唾液'
      } else {
        return '未知'
      }
    },
    // 跳转用户详情
    async goOrderInfo(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, pageNum: this.queryParams.pageNum, type: 4 } })
    },
    // 预约详情
    async delHandler(orderId) {
      this.$router.push({ path: 'particulars', query: { orderId: orderId, pageNum: this.queryParams.pageNum, particularsType: 3 } })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>