<!-- 订单详情 -->
<template>
  <div v-loading.fullscreen.lock="loading"
       element-loading-text=" 报告上传中...">
    <el-card>
      <div class="headline">订单信息</div>
      <div class="basicsHeadline">基础信息</div>
      <el-descriptions>
        <el-descriptions-item label="订单号">{{ordersList.id}}</el-descriptions-item>
        <el-descriptions-item label="账单号">{{ordersList.billNo}}</el-descriptions-item>
        <el-descriptions-item label="产品名称">{{ordersList.productName}}</el-descriptions-item>
        <el-descriptions-item label="订单状态">
          <el-tag size="small">{{ordersList.status==0?"未付款":ordersList.status==1?'已付款':'已退款'}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="价格"> {{ordersList.price}}</el-descriptions-item>
        <el-descriptions-item label="付款类型">{{ordersList.payType==1?'支付宝':ordersList.payType==2?'微信':ordersList.payType==3?'银行卡':'现金'}}</el-descriptions-item>
        <el-descriptions-item label="付款时间">{{ordersList.payTime}}</el-descriptions-item>
        <el-descriptions-item label="订单生成时间">{{ordersList.createTime}}</el-descriptions-item>
        <el-descriptions-item label="用户名">{{ordersList.userName}}</el-descriptions-item>
        <el-descriptions-item label="用户手机号">
          <el-link type="primary"
                   @click="gotoUserInfo(ordersList.phone)">{{ordersList.phone}}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="来源">{{ordersList.source==1?'销售端':ordersList.source==2?'产品端':ordersList.source==3?'线下':'京东'}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{ordersList.remarks}}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div class="elseHeadline"
           v-if="ordersList.originalPrice||ordersList.discountCode||ordersList.discount||ordersList.vipCardNumber||ordersList.shareOrderPhone">其他信息</div>
      <el-descriptions>
        <el-descriptions-item v-if="ordersList.originalPrice"
                              label="订单原价">{{ordersList.originalPrice}}</el-descriptions-item>
        <el-descriptions-item v-if="ordersList.discountCode"
                              label="使用优惠码">{{ordersList.discountCode}}</el-descriptions-item>
        <el-descriptions-item v-if="ordersList.discount"
                              label="抵扣价">{{ordersList.discount}}</el-descriptions-item>
        <el-descriptions-item v-if="ordersList.vipCardNumber"
                              label="使用vip卡号">{{ordersList.vipCardNumber}}</el-descriptions-item>
        <el-descriptions-item v-if="ordersList.shareOrderPhone"
                              label="推荐用户手机号">{{ordersList.shareOrderPhone}}</el-descriptions-item>
      </el-descriptions>
      <el-form>
        <el-button v-if="ordersList.valid==0&&orderAuth>1"
                   type="danger"
                   size="small"
                   icon="el-icon-delete"
                   @click="updateHandler(ordersList.id)&&orderAuth>1">失效</el-button>
        <el-button v-if="ordersList.valid==0&&ordersList.status==1&&orderAuth>1&&!ordersList.reportStatus"
                   type="warning"
                   icon="el-icon-s-release"
                   size="small"
                   @click="drawback(ordersList.id)">退款</el-button>
        <el-button v-if="ordersList.valid==0&&ordersList.status==0&&orderAuth>1"
                   type="success"
                   size="small"
                   @click="confirmOrder(ordersList.id,ordersList.source)">确认收款</el-button>
      </el-form>
    </el-card>
    <el-card style="margin-top:15px"
             v-if="ordersList.status==1">
      <div class="headline">报告信息</div>
      <el-descriptions>
        <el-descriptions-item label="报告状态">
          <el-tag size="small">{{ordersList.reportStatus==1?'未出报告':ordersList.reportStatus==2?'已出报告':ordersList.reportStatus==3?'已邮寄报告':'未采样'}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="是否需要纸质报告">
          <el-tag v-if="ordersList.needPagerReport"
                  size="small"
                  type="success">是</el-tag>
          <el-tag v-else
                  size="small"
                  type="info">否</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="报告编号">{{ordersList.reportNumber}}</el-descriptions-item>
        <el-descriptions-item label="邮寄地址">{{ordersList.address==null?'待用户填写':ordersList.address}}</el-descriptions-item>
        <el-descriptions-item label="报告时间">{{ordersList.reportTime}}</el-descriptions-item>
        <el-descriptions-item label="检测报告"
                              v-if="ordersList.reportStatus==2||ordersList.reportStatus==3">
          <div style="margin-right:20px;"
               v-for="item in ordersList.reportList"
               :key="item.index"> <a target="_blank"
               class="link"
               :href="$url+item+'?key='+headers.token">{{item.slice(item.indexOf("-")+1,item.length)}}</a></div>
        </el-descriptions-item>
      </el-descriptions>
      <el-form v-if="reportAuth>1">
        <el-button v-if="(ordersList.reportStatus==2||ordersList.reportStatus==1)&&ordersList.isCanUpload"
                   size="small"
                   @click="uploadingReport(ordersList.id)"
                   type="primary">上传报告</el-button>
        <el-button @click="mailReport=true"
                   size="small"
                   v-if="ordersList.reportList&&ordersList.reportStatus==2&&ordersList.address"
                   type="info">已邮寄报告</el-button>
        <el-button @click="deleteReport=true"
                   size="small"
                   v-if="ordersList.reportStatus==2"
                   icon="el-icon-delete"
                   type="danger">删除报告</el-button>
      </el-form>
      <div v-if="ordersList.reportStatus==1&&!ordersList.isCanUpload"
           style="color:red">样本信息未填写完整,暂不能上传报告!</div>
    </el-card>

    <template>
      <el-dialog title="上传报告"
                 :visible.sync="uploadFlag"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form ref="uploadReportList"
                   :rules="rulesUploadRepor"
                   :model="uploadReportList"
                   label-width="80px">
            <el-form-item label="样本"
                          prop="sampleId">
              <el-select v-model="uploadReportList.sampleId"
                         placeholder="请选择样本">
                <el-option v-for="item in sampleIdList"
                           :key="item.id"
                           :label="item.number"
                           :value="item.id"></el-option>

              </el-select>
            </el-form-item>
            <el-form-item label="样本编号"
                          prop="sampleNo">
              <el-input v-model="uploadReportList.sampleNo"></el-input>
            </el-form-item>
            <el-form-item label="报告">
              <el-upload class="upload-demo"
                         ref="upload"
                         name="report"
                         multiple
                         :on-change="fileChange"
                         :headers="headers"
                         :on-remove="onRemove"
                         :before-upload="beforeAvatarUpload"
                         action="#"
                         :file-list="fileList"
                         :auto-upload="false">
                <el-button slot="trigger"
                           size="small"
                           type="primary">选择文件</el-button>
                <div slot="tip"
                     class="el-upload__tip">只能上传PDF文件，且不超过20M</div>
              </el-upload>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="uploadFlag =false">取 消</el-button>
          <el-button type="primary"
                     @click="uploadReport('uploadReportList')">提交</el-button>
        </span>
      </el-dialog>
    </template>
    <template>
      <el-dialog title="确认已邮寄报告"
                 :visible.sync="mailReport"
                 width="35%">
        <div class="demo-input-suffix">
          <el-form ref="mailtList"
                   :rules="rules"
                   :model="mailtList"
                   label-width="80px">
            <el-form-item label="快递公司"
                          prop="company">
              <el-select v-model="mailtList.company"
                         filterable
                         allow-create
                         placeholder="请选择或输入快递公司">
                <el-option label="顺丰快递"
                           value="顺丰快递"></el-option>
                <el-option label="邮政快递"
                           value="邮政快递"></el-option>
                <el-option label="京东快递"
                           value="京东快递"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="寄件单号"
                          prop="number">
              <el-input v-model="mailtList.number"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="mailReport = false">取 消</el-button>
          <el-button type="primary"
                     @click="confirmReportMail('mailtList')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <template>
      <el-dialog title="删除报告"
                 :visible.sync="deleteReport"
                 width="35%">
        <div class="demo-input-suffix">
          <ul>
            <li v-for="(item,index) in ordersList.reportList"
                :key="item.index"
                style=" display: flex;justify-content:space-between;">
              <a target="_blank"
                 style="margin-top:5px; color:#66b1ff"
                 :href="$url+item+'?key='+headers.token">{{item.slice(item.indexOf("-")+1,item.length)}}</a>
              <el-button style='color:red'
                         type="text"
                         @click="delReport(index)">删除</el-button>
            </li>
          </ul>
        </div>

      </el-dialog>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Orders',
  data() {
    return {
      orderid: '',
      pageNum: '',
      ordersList: {},
      orderAuth: '',
      reportAuth: '',
      uploadFlag: false,
      addSample: false,
      mailReport: false,
      deleteReport: false,
      headers: {
        token: sessionStorage.getItem('token')
      },
      fileList: [],
      mailtList: {
        company: '',
        number: ''
      },
      rules: {
        number: [{ required: true, message: '请输入寄件单号', trigger: 'blur' }],
        company: [{ required: true, message: '请选择快递公司', trigger: 'change' }]
      },
      rulesUploadRepor: {
        sampleNo: [{ required: true, message: '请输入样本编号', trigger: 'blur' }],
        sampleId: [{ required: true, message: '请选择样本', trigger: 'change' }]
      },
      uploadReportList: {
        sampleId: '',
        sampleNo: '',
        reports: []
      },
      sampleIdList: [],
      loading: false
    }
  },
  mounted() {
    this.pageNum = this.$route.query.pageNum
    this.orderid = this.$route.query.orderId
    this.getOrderInfoById()
    this.getAuthAttr()
  },

  methods: {
    // 上传报告
    uploadingReport(id) {
      this.uploadFlag = true
      this.getCanReportSampleList(id)
    },
    async getCanReportSampleList(id) {
      const { data: res } = await this.$http.get(`/admin/sample/getCanReportSampleList?orderId=${id}`)
      if (res.code == 200) {
        this.sampleIdList = res.data
      }
    },
    async getOrderInfoById() {
      const { data: res } = await this.$http.get(`/admin/order/getOrderInfoById?id=${this.orderid}`)
      if (res.code == 200) {
        this.ordersList = res.data
      }
    },
    // 获取订单权限
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-3')
      var authAttr2 = window.sessionStorage.getItem('auth-8')
      this.orderAuth = authAttr
      this.reportAuth = authAttr2
    },
    fileChange(uploadFile, fileList) {
      this.fileList = fileList
    },
    onRemove() {
      this.loading = false
    },
    beforeAvatarUpload(file) {
      const isPDF = file.type === 'application/pdf' || file.type === 'application/PDF'
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isPDF) {
        this.$message.error('文件类型只能是PDF格式!')
      }
      if (!isLt20M) {
        this.$message.error('文件大小不能超过20MB!')
      }
      return isPDF && isLt20M
    },

    uploadReport(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.fileList.length == 0) {
            this.$message.error('不能上传空文件')
          } else {
            this.loading = true
            let fileFormData = new FormData()
            this.fileList.forEach((f) => {
              fileFormData.append('reports', f.raw)
            })
            fileFormData.append('id', this.orderid)
            fileFormData.append('sampleId', this.uploadReportList.sampleId)
            fileFormData.append('sampleNo', this.uploadReportList.sampleNo)
            this.uploadFlag = false
            this.$http.post(`/admin/report/uploadReport`, fileFormData).then((res) => {
              if (res.data.code == 200) {
                this.loading = false
                this.$message.success('上传成功')
                this.$refs.upload.clearFiles()
                this.fileList = []
                this.$emit('watchChild')
              } else {
                this.loading = false
                this.$message.error(res.data.msg)
                this.$refs.upload.clearFiles()
                this.fileList = []
              }
              this.getOrderInfoById()
            })
          }
        }
      })
    },
    confirmReportMail(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.get(`/admin/report/confirmReportMail?id=${this.orderid}&company=${this.mailtList.company}&number=${this.mailtList.number}`)
          if (res.code != 200) {
            this.mailReport = false
            return this.$message.error(res.msg)
          } else {
            this.mailReport = false
            this.getOrderInfoById()
            this.$emit('watchChild')
            return this.$message.success('确认邮寄报告成功')
          }
        }
      })
    },
    // 删除报告按钮
    async delReport(index) {
      const msg = await this.$confirm('您确认要删除该报告吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/admin/report/delReport?id=${this.orderid}&index=${index}`)
        if (res.code != 200) {
          return this.$message.error(res.msg)
        } else {
          this.$message.success('删除报告成功')
          this.getOrderInfoById()
          this.$emit('watchChild')
        }
      }
    },
    gotoUserInfo(phone) {
      this.$router.push({ path: 'userInfo', query: { phone: phone, type: 1 } })
    },
    // 失效
    async updateHandler(id) {
      const msg = await this.$confirm('你确定要失效该订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.post(`/admin/order/delOrder?id=${id}`)
        if (res.code == 200) {
          this.$message.success('订单已失效')
          this.back()
        } else {
          return this.$message.error('失效失败')
        }
      }
    },
    //退款
    async drawback(id) {
      const msg = await this.$confirm('你确定需要退款?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.post(`/admin/order/backOrder?id=${id}`)
        if ((res.code = 200)) {
          this.$message.success('退款成功')
          this.getOrderInfoById()
          this.$emit('watchChild')
        } else {
          return this.$message.error('退款失败')
        }
      }
    },
    // 确认收款
    async confirmOrder(id, source) {
      if (source == 1 || source == 2) {
        var e = await this.$prompt('您正在确认账单,请填写账单号', '提示', {
          inputPlaceholder: '请输入账单号',
          confirmButtonText: '确定提交',
          cancelButtonText: '取消',
          closeOnClickModal: true,
          type: 'warning'
        })
        if (e.action == 'confirm' && e.value != null && e.value != '' && e.value.length >= 10 && e.value.length <= 100) {
          const { data: res } = await this.$http.post(`/admin/order/confirmOrder?id=${id}&payNo=${e.value}&flag=1`)
          if ((res.code = 200)) {
            this.getOrderInfoById()
            this.$emit('watchChild')
            return this.$message.success('确认成功')
          }
        } else {
          this.$message.error('输入错误')
        }
      } else {
        const msg = await this.$confirm('你确定该订单已支付吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (msg === 'confirm') {
          //做删除操作
          const { data: res } = await this.$http.post(`/admin/order/confirmOrder?id=${id}&flag=1`)
          if ((res.code = 200)) {
            this.getOrderInfoById()
            this.$emit('watchChild')
            return this.$message.success('确认成功')
          }
        } else {
          this.$message.error('输入错误')
        }
      }
    },
    // 返回订单管理
    back() {
      this.$router.push({ path: '/order', query: { pageNum: this.pageNum } })
    }
  }
}
</script>
<style lang='scss' scoped>
.headline {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}
.basicsHeadline {
  font-size: 15px;
  margin: 10px 0px;
  font-weight: 600;
}
.elseHeadline {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}
.link:hover {
  color: #66b1ff;
  text-decoration: underline;
}
</style>