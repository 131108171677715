<!-- 个人角色权限管理 -->
<template>
  <div>
    <el-form label-width="80px"
             ref="myform"
             size="small"
             :inline="true">
      <el-form-item>
        <el-button type="success"
                   @click="back">返回人员管理</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="danger"
                   @click="clearAdminAuth">清空个人权限配置</el-button>
      </el-form-item>

    </el-form>
    <!-- 表格 -->
    <el-table :data="jurisdictionList"
              align="center"
              style="width: 100%"
              :header-cell-style="{background: '#f8f8f9'}">
      <el-table-column prop="type"
                       align="center"
                       label="权限类型"
                       :formatter="permissiontype">
      </el-table-column>
      <el-table-column label="属性"
                       align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.attribute"
                     active-color="#13ce66"
                     inactive-color="#99CCFF"
                     :active-value="1"
                     :inactive-value="2"
                     active-text="读"
                     inactive-text="写"
                     @change="setAdminAuth(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="status"
                       align="center"
                       label="状态">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status"
                     active-color="#13ce66"
                     inactive-color="#ff4949"
                     :active-value="0"
                     :inactive-value="1"
                     active-text="启用"
                     inactive-text="禁用"
                     @change="setAdminAuth(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="remarks"
                       align="center"
                       label="备注">
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jurisdictionList: [],
      queryParams: {
        type: '',
        attribute: '',
        identity: ''
      },
      id: '',
      pageNum: ''
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.pageNum = this.$route.query.pageNum
    this.getAdminAuth()
  },
  methods: {
    // 获取管理员权限
    async getAdminAuth() {
      const { data: res } = await this.$http.get(`/admin/admin/getAdminAuth?id=${this.id}`)
      this.jurisdictionList = res.data
      if (res.code != 200) return this.$message.error('权限不够哦！')
    },
    // 清空个人权限
    async clearAdminAuth() {
      const msg = await this.$confirm('你确定要清空所有权限配置吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        //做删除操作
        const { data: res } = await this.$http.get(`/admin/admin/clearAdminAuth?adminId=${this.id}`)
        if (res.code == 200) {
          this.$message.success('清空配置成功')
        } else {
          this.$message.error(res.msg)
        }
        this.getAdminAuth()
      }
    },
    // 判断属性
    messageTypeFormat(row) {
      if (row.attribute == 1) {
        return '读'
      } else {
        return '写'
      }
    },
    // 判断权限类型
    permissiontype(row) {
      if (row.type == 1) {
        return '文章'
      } else if (row.type == 2) {
        return '用户'
      } else if (row.type == 3) {
        return '订单'
      } else if (row.type == 4) {
        return '产品'
      } else if (row.type == 5) {
        return '管理员'
      } else if (row.type == 6) {
        return '样本'
      } else if (row.type == 7) {
        return '预约'
      } else if (row.type == 8) {
        return '报告'
      } else if (row.type == 9) {
        return '知情同意书'
      } else if (row.type == 10) {
        return '优惠码'
      } else if (row.type == 11) {
        return '资产'
      } else if (row.type == 12) {
        return '收集'
      } else if (row.type == 13) {
        return 'VIP卡'
      } else if (row.type == 14) {
        return '代理'
      } else if (row.type == 15) {
        return '活动'
      }
    },
    // 修改权限状态
    async setAdminAuth(row) {
      var obj = {
        id: row.id,
        adminId: this.id,
        attribute: row.attribute,
        status: row.status
      }
      const { data: res } = await this.$http.post('/admin/admin/setAdminAuth', obj)
      if (res.code == 200 && res.data == 1) {
        this.$message.success('修改状态成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    // 返回人员管理
    back() {
      this.$router.push({ path: '/admin/personnel', query: { pageNum: this.pageNum } })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>