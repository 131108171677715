<template>
  <!--  vip -->
  <div>
    <!-- 顶部搜索 -->
    <el-form ref="myform"
             size="small"
             :inline="true"
             label-width="58px">
      <el-form-item>
        <el-button v-if="authAttr>1"
                   @click="openVipDialog()"
                   type="primary"
                   plain
                   size="medium"
                   icon="el-icon-plus">添加活动</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <!-- (CURD)操作按钮 -->
      <!-- 表格区域 -->
      <el-table :data="activityList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="name"
                         align="center"
                         label="活动名称">
        </el-table-column>
        <el-table-column prop="startTime"
                         align="center"
                         label="活动开始时间">
        </el-table-column>
        <el-table-column prop="endTime"
                         align="center"
                         label="活动结束时间">
        </el-table-column>

        <el-table-column label="操作">
          <template v-slot="{row}">
            <el-button-group>
              <el-button v-if='authAttr>0'
                         type="success"
                         size="mini"
                         icon="el-icon-tickets"
                         @click="goto(row.id)">详情</el-button>
              <el-button v-if="authAttr>1"
                         type="primary"
                         size="mini"
                         icon="el-icon-edit"
                         @click="modificationActivity(row)">编辑</el-button>
              <el-button v-if="authAttr>1"
                         @click="delActivity(row.id)"
                         type="danger"
                         size="mini"
                         icon="el-icon-delete">删除</el-button>
            </el-button-group>

          </template>
        </el-table-column>

      </el-table>
      <!-- 分页导航 -->
      <template>
        <el-row class="pagination">
          <pagination v-show="total>0"
                      :total="total"
                      :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getActivityList" />
        </el-row>
      </template>
    </el-card>

    <!-- 添加活动-->
    <template>
      <el-dialog title="添加活动"
                 :visible.sync="addDialogVisible"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="recompose"
                   ref="recompose"
                   :rules="rules"
                   label-width="100px">
            <el-form-item label="活动名称"
                          prop="name">
              <el-input v-model="recompose.name"
                        placeholder="请输入活动名称"></el-input>
            </el-form-item>
            <el-form-item label="活动时间"
                          prop="data"
                          required>
              <el-date-picker v-model="recompose.data"
                              value-format="yyyy-MM-dd HH:mm"
                              type="datetimerange"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>

          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="resetForm('recompose')">取 消</el-button>
          <el-button type="success"
                     @click="addActivity('recompose')">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <!-- 修改活动 -->
    <template>
      <el-dialog title="修改活动"
                 :visible.sync="discountDialogs"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="modifyActivityList"
                   ref="modifyActivityList"
                   :rules="rules"
                   label-width="100px">
            <el-form-item label="活动名称"
                          prop="name">
              <el-input v-model="modifyActivityList.name"
                        placeholder="请输入活动名称"></el-input>
            </el-form-item>
            <el-form-item label="活动时间"
                          prop="data"
                          required>
              <el-date-picker v-model="modifyActivityList.data"
                              value-format="yyyy-MM-dd HH:mm"
                              type="datetimerange"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="discountDialogs = false">取 消</el-button>
          <el-button type="success"
                     @click="submitModifyActivity('modifyActivityList')">提交</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination
  },
  data() {
    var checkData = (rule, value, callback) => {
      if (value == '') {
        return callback(new Error('请选择活动时间'))
      } else if (value == null) {
        return callback(new Error('请选择活动时间'))
      }

      callback()
    }
    return {
      recompose: {
        name: '',
        data: ''
      },

      activityList: [],
      //获取用户列表传参
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      // 控制修改密码
      addDialogVisible: false,
      // 对话框
      discountDialogs: false,
      authAttr: '',
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        data: [{ validator: checkData, required: true, trigger: 'change' }]
      },
      addActivityList: {
        name: '',
        startTime: '',
        endTime: ''
      },
      modifyActivityList: {
        name: '',
        data: [],
        activityId: ''
      },
      modifyActivityParameters: {
        name: '',
        startTime: '',
        endTime: '',
        id: ''
      }
    }
  },
  created() {
    if (this.$route.query.pageNum != 1 && this.$route.query.pageNum != undefined) {
      this.queryParams.pageNum = Number(this.$route.query.pageNum)
    } else {
      this.queryParams.pageNum = 1
    }
  },
  mounted() {
    this.getActivityList()
    this.getAuthAttr()
  },
  methods: {
    goto(id) {
      this.$router.push({ path: 'detailedActivity', query: { activityId: id } })
    },
    openVipDialog() {
      this.addDialogVisible = true
      // this.getAddableProductList()
    },
    // 搜索按钮
    inquire() {
      this.queryParams.pageNum = 1
      this.getActivityList()
    },
    getAuthAttr() {
      var authAttr = window.sessionStorage.getItem('auth-15')
      this.authAttr = authAttr
    },
    // 查询活动列表
    async getActivityList() {
      const { data: res } = await this.$http.get('/admin/activity/getActivityList', { params: this.queryParams })
      this.activityList = res.data.list
      this.total = res.data.total
    },

    // 删除活动
    async delActivity(id) {
      const msg = await this.$confirm(`你确定要删除改活动吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (msg === 'confirm') {
        const { data: res } = await this.$http.get(`/admin/activity/delActivity?activityId=${id}`)
        if (res.code == 200) {
          this.$message.success('删除成功!')
          this.getActivityList()
        } else {
          this.$message.error(res.msg)
        }
      }
    },

    // 添加活动
    addActivity(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.addDialogVisible = false
          console.log(this.recompose.data)
          this.addActivityList.name = this.recompose.name
          this.addActivityList.startTime = this.recompose.data[0]
          this.addActivityList.endTime = this.recompose.data[1]
          const { data: res } = await this.$http.post('/admin/activity/addActivity', this.addActivityList)
          if (res.code == 200) {
            this.getActivityList()
            this.$message.success('添加活动成功')
          } else {
            this.$message.warning(res.msg)
          }
          this.$refs[formName].resetFields()
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.addDialogVisible = false
    },
    modificationActivity(row) {
      this.modifyActivityList.name = row.name
      this.modifyActivityParameters.id = row.id
      this.modifyActivityList.data[0] = row.startTime
      this.modifyActivityList.data[1] = row.endTime
      this.discountDialogs = true
    },
    submitModifyActivity(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.discountDialogs = false
          this.modifyActivityParameters.name = this.modifyActivityList.name
          this.modifyActivityParameters.startTime = this.modifyActivityList.data[0]
          this.modifyActivityParameters.endTime = this.modifyActivityList.data[1]
          const { data: res } = await this.$http.post(`/admin/activity/modifyActivity`, this.modifyActivityParameters)
          if (res.code == 200) {
            this.getActivityList()
            this.$message.success('修改活动成功')
          } else {
            this.$message.warning(res.msg)
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
</style>