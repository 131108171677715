<!-- 代理详情 -->
<template>
  <div>
    <template>
      <!-- 变更用户收益对话框 -->
      <el-dialog title="变更收益/积金"
                 :visible.sync="changeCommissionDialogue"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form ref="changeCommissionList"
                   :rules="rules"
                   :model="changeCommissionList"
                   label-width="90px">
            <el-form-item label="类型"
                          prop="type">
              <el-radio-group v-model="changeCommissionType">
                <el-radio :label="1">增加</el-radio>
                <el-radio :label="2">减少</el-radio>
              </el-radio-group>
            </el-form-item>
            <div style=" display: flex;">
              <el-form-item label="积金">
                <el-input-number :min="0"
                                 :controls="false"
                                 v-model="changeCommissionList.commission"></el-input-number>
              </el-form-item>
              <el-form-item label="收益">
                <el-input-number :min="0"
                                 :controls="false"
                                 v-model="changeCommissionList.profit"></el-input-number>
              </el-form-item>
            </div>
            <el-form-item label="备注"
                          prop="remarks">
              <el-input v-model="changeCommissionList.remarks"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="changeCommissionDialogue = false">取 消</el-button>
          <el-button type="success"
                     @click="changeCommission('changeCommissionList')">提交</el-button>
        </span>
      </el-dialog>
      <el-dialog title="转账"
                 :visible.sync="changeEftDialogue"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form ref="changeCommissionList"
                   :rules="rulesEft"
                   :model="changeCommissionList"
                   label-width="90px">
            <el-form-item label="积金">
              <el-input-number :min="0"
                               :controls="false"
                               v-model="changeCommissionList.commission"></el-input-number>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="changeEftDialogue = false">取 消</el-button>
          <el-button type="success"
                     @click="changeEft('changeCommissionList')">提交</el-button>
        </span>
      </el-dialog>
    </template>
    <el-card class="box-card">
      <el-descriptions title="代理信息">
        <template slot="extra"
                  v-if="agencyAuth>1">
          <el-button v-if="agencyAuth>1"
                     type="primary"
                     size="small"
                     @click="changeEftDialogue=true">转账</el-button>
          <el-button v-if="agencyAuth>1"
                     type="primary"
                     size="small"
                     @click="changeCommissionDialogue=true">变更收益/积金</el-button>
          <el-button type="primary"
                     size="small"
                     @click="openAgencyDialog">编辑代理</el-button>
        </template>
        <el-descriptions-item label="用户">{{textFilter(proxyInfoList.phone,proxyInfoList.username)}}</el-descriptions-item>
        <el-descriptions-item label="代理角色"
                              v-if="proxyInfoList.proxyRole!=null">
          <el-tag size="small">{{proxyInfoList.proxyRole==1?'市级代理':proxyInfoList.proxyRole==2?'区县代理':proxyInfoList.proxyRole==0?'特级代理':'个人代理'}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="上级代理"
                              v-if="proxyInfoList.topPhone">
          <el-link :underline="false"
                   type="primary"
                   @click.native="goto(proxyInfoList.topPhone)"> {{textFilter(proxyInfoList.topPhone,proxyInfoList.topName)}}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="推荐人"
                              v-if="proxyInfoList.recommenderPhone">
          <el-link :underline="false"
                   type="primary"
                   @click.native="goto(proxyInfoList.recommenderPhone)">{{textFilter(proxyInfoList.recommenderPhone,proxyInfoList.recommenderName)}}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="居间人">
          <el-link :underline="false"
                   type="primary"
                   @click.native="goto(proxyInfoList.intermediaryPhone)">{{textFilter(proxyInfoList.intermediaryPhone,proxyInfoList.intermediaryName)}}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="代理创建时间">{{proxyInfoList.createTime}}</el-descriptions-item>
        <el-descriptions-item label="积金">{{proxyInfoList.commission}}</el-descriptions-item>
        <el-descriptions-item label="收益">{{proxyInfoList.profit}}</el-descriptions-item>
      </el-descriptions>
    </el-card>

    <template>
      <!-- 编辑代理对话框 -->
      <el-dialog title="编辑代理"
                 :visible.sync="changeAgencyInfoDialogue"
                 width="50%">
        <div class="demo-input-suffix">
          <el-form :model="showUpdateUserRecommenderObj"
                   ref="updateUserRecommenderObj"
                   label-width="100px">
            <el-form-item label="推荐人手机号"
                          prop="userId">
              <el-select v-model="showUpdateUserRecommenderObj.recommender"
                         @change="changeRecommender($event)"
                         clearable
                         filterable
                         @click.native="userJumpChange"
                         :filter-method="(value) => getAddableUser(value, 4)"
                         placeholder="请输入用户手机号">
                <el-option v-for="item in options"
                           :key="item.id"
                           :label='item.label'
                           :value="item.id">
                </el-option>
              </el-select>

            </el-form-item>
          </el-form>
        </div>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="changeAgencyInfoDialogue = false">取 消</el-button>
          <el-button type="success"
                     @click="updateUserRecommender()">提交</el-button>
        </span>
      </el-dialog>
    </template>

    <div class="head">
      <h1 class="title">下级代理</h1>
    </div>
    <el-card class="box-card">
      <el-table :data="proxyTreeList"
                style="width: 100%"
                row-key="phone"
                border
                :tree-props="{children: 'subProxyResList'}">
        <el-table-column prop="phone"
                         label="手机号"
                         width="180">
          <template slot-scope="scope">
            <el-link :underline="false"
                     type="primary"
                     @click.native="goto(scope.row.phone)">{{scope.row.phone}}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="姓名"
                         width="180">
        </el-table-column>
        <el-table-column prop="saleCount"
                         label="销售量"
                         width="180">
        </el-table-column>
        <el-table-column prop="proxyRole"
                         label="代理角色"
                         :formatter="identity"
                         width="180">
        </el-table-column>

      </el-table>
    </el-card>
    <div class="head">
      <h1 class="title">推荐用户</h1>
    </div>
    <el-card class="box-card">
      <el-table :data="recommenderUserList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="name"
                         align="center"
                         label="姓名">
        </el-table-column>

        <el-table-column prop="phone"
                         align="center"
                         label="手机号"></el-table-column>
        <el-table-column prop="orderCount"
                         align="center"
                         label="下单数量"></el-table-column>
        <el-table-column prop="createTime"
                         align="center"
                         label="创建时间"></el-table-column>
      </el-table>
      <template>
        <el-row class="pagination">
          <pagination v-show="recommenderUserTotal>0"
                      :total="recommenderUserTotal"
                      :page.sync="getRecommenderUserParams.pageNum"
                      :limit.sync="getRecommenderUserParams.pageSize"
                      @pagination="getRecommenderUserList" />
        </el-row>
      </template>
    </el-card>
    <div style="padding-top:20px">
      <el-form :model="queryParams"
               ref="queryParams"
               size="small"
               :inline="true"
               label-width="108px">
        <el-form-item>
          <h1>变更记录</h1>
        </el-form-item>
        <el-form-item label-width="68px">
          <el-select v-model="queryParams.type"
                     placeholder="变更类型"
                     clearable>
            <el-option label="订单返利"
                       value="1"></el-option>
            <el-option label="居间费"
                       value="2"></el-option>
            <el-option label="后台操作"
                       value="3"></el-option>
            <el-option label="特级居间费"
                       value="4"></el-option>
            <el-option label="提现"
                       value="5"></el-option>
            <el-option label="转账"
                       value="6"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label-width="68px"
                      v-if="!queryParams.isSelf">
          <el-input v-model="queryParams.searchPhone"
                    placeholder="下级手机号"></el-input>
        </el-form-item>
        <el-form-item label-width="68px">
          <el-input v-model="queryParams.recommended"
                    placeholder="下单用户手机号"></el-input>
        </el-form-item>
        <el-form-item label-width="68px">
          <el-input v-model="queryParams.orderId"
                    placeholder="订单号"></el-input>
        </el-form-item>
        <el-form-item label-width="68px">
          <el-select v-model="queryParams.isSelf"
                     clearable>
            <el-option label="本人"
                       :value=true></el-option>
            <el-option label="下级代理"
                       :value=false></el-option>

          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="value1"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          format="yyyy 年 MM 月 dd 日"
                          value-format="yyyy-MM-dd"
                          @change="changeValue1">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     size="medium"
                     icon="el-icon-search"
                     @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card class="box-card">
      <!-- 数据表格 -->
      <template>
        <el-table :data="commissionRecordList"
                  align="center"
                  style="width: 100%"
                  :header-cell-style="{background: '#f8f8f9'}">
          <el-table-column prop="userName"
                           align="center"
                           label="用户">
            <template slot-scope="scope">
              <div type="primary"
                   :underline="false">{{textFilter(scope.row.userPhone,scope.row.userName)}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="commission"
                           align="center"
                           label="积金">
          </el-table-column>
          <el-table-column prop="profit"
                           align="center"
                           label="收益"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="recommendedPhone"
                           align="center"
                           label="下单用户"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goto(scope.row.recommendedPhone)">{{textFilter(scope.row.recommendedPhone,scope.row.recommendedName)}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="recommenderPhone"
                           align="center"
                           label="推荐人"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-link type="primary"
                       :underline="false"
                       @click.native="goto(scope.row.recommenderPhone)">{{textFilter(scope.row.recommenderPhone,scope.row.recommenderName)}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="type"
                           align="center"
                           :formatter="typeFormatter"
                           label="类型"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="orderId"
                           align="center"
                           show-overflow-tooltip
                           label="订单号">
            <template slot-scope="scope"
                      v-if="orderAttr>=1">
              <el-link type="primary"
                       :underline="false"
                       @click.native="gotoOrderld(scope.row.orderId,)">{{scope.row.orderId}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="remarks"
                           align="center"
                           show-overflow-tooltip
                           label="备注">
          </el-table-column>
          <el-table-column prop="createTime"
                           align="center"
                           label="时间"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.createTime| datefmt('YYYY-MM-DD HH:mm')}}</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页导航 -->
        <template>
          <el-row class="pagination">
            <pagination v-show="total>0"
                        :total="total"
                        :page.sync="queryParams.pageNum"
                        :limit.sync="queryParams.pageSize"
                        @pagination="getCommissionRecordList" />
          </el-row>
        </template>
      </template>
    </el-card>
    <div class="head">
      <h1 class="title">下单记录</h1>
    </div>
    <el-form :model="getProxyOrderParams"
             size="small"
             :inline="true"
             label-width="108px">
      <el-form-item label-width="68px">
        <el-input v-model="getProxyOrderParams.keyword"
                  placeholder="用户手机号或姓名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="value2"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy 年 MM 月 dd 日"
                        @change="changeProxyOrder"
                        value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   size="medium"
                   icon="el-icon-search"
                   @click="searchProxyOrder">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-card class="box-card">
      <el-table :data="proxyOrderList"
                align="center"
                style="width: 100%"
                :header-cell-style="{background: '#f8f8f9'}">
        <el-table-column prop="orderId"
                         align="center"
                         label="订单号">
        </el-table-column>

        <el-table-column prop="productName"
                         align="center"
                         label="产品名称"></el-table-column>
        <el-table-column prop="phone"
                         align="center"
                         label="用户">
          <template slot-scope="scope">
            <div type="primary"
                 :underline="false">{{textFilter(scope.row.phone,scope.row.name)}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime"
                         align="center"
                         label="下单时间"></el-table-column>
      </el-table>
      <template>
        <el-row class="pagination">
          <pagination v-show="proxyOrderTotal>0"
                      :total="proxyOrderTotal"
                      :page.sync="getProxyOrderParams.pageNum"
                      :limit.sync="getProxyOrderParams.pageSize"
                      @pagination="getProxyOrderList" />
        </el-row>
      </template>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
export default {
  name: 'UserAgency',
  components: {
    Pagination
  },
  props: {
    phone: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value1: '',
      value2: '',
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phone: '',
        type: '',
        isSelf: true,
        startTime: '',
        endTime: '',
        searchPhone: '',
        recommended: '',
        orderId: ''
      },
      getRecommenderUserParams: {
        phone: '',
        pageSize: 10,
        pageNum: 1
      },
      getProxyOrderParams: {
        phone: '',
        keyword: '',
        pageSize: 10,
        pageNum: 1,
        startTime: '',
        endTime: ''
      },
      proxyOrderTotal: 0,
      proxyOrderList: [],
      recommenderUserTotal: 0,
      commissionRecordList: [],
      proxyInfoList: {},
      changeCommissionList: {
        phone: '',
        remarks: '',
        commission: 0,
        profit: 0,
        type: ''
      },
      changeCommissionType: '',
      total: 0,
      changeEftDialogue: false,
      changeCommissionDialogue: false,
      changeAgencyInfoDialogue: false,
      agencyAuth: '',
      orderAttr: '',
      proxyTreeList: [],
      recommenderUserList: [],
      updateUserRecommenderObj: {
        phone: '',
        recommender: ''
      },
      options: [],
      showUpdateUserRecommenderObj: {
        recommender: ''
      },
      rules: {
        remarks: [{ required: true, message: '请填写变更缘由', trigger: 'blur' }]
      },
      rulesEft: {
        commission: [{ required: true, message: '请输入转账金额', trigger: 'blur' }]
      }
    }
  },
  watch: {
    // 路由发生变化刷新整个组件
    $route() {
      window.location.reload()
    }
  },
  mounted() {
    this.queryParams.phone = this.phone
    this.getRecommenderUserParams.phone = this.phone
    this.getProxyOrderParams.phone = this.phone
    this.changeCommissionList.phone = this.phone
    this.getProxyInfo()
    this.getCommissionRecordList()
    this.getAuthAttr()
    this.getProxyTree()
    this.getRecommenderUserList()
    this.getProxyOrderList()
  },

  methods: {
    changeProxyOrder(e) {
      console.log(e)
      if (e == null) {
        this.getProxyOrderParams.startTime = ''
        this.getProxyOrderParams.endTime = ''
      } else {
        this.getProxyOrderParams.startTime = e[0]
        this.getProxyOrderParams.endTime = e[1]
      }
    },
    changeValue1(e) {
      if (e == null) {
        this.queryParams.startTime = ''
        this.queryParams.endTime = ''
      } else {
        this.queryParams.startTime = e[0]
        this.queryParams.endTime = e[1]
      }
    },
    searchProxyOrder() {
      this.getProxyOrderParams.pageNum = 1
      this.getProxyOrderList()
    },
    search() {
      this.queryParams.pageNum = 1
      this.getCommissionRecordList()
    },
    changeRecommender(e) {
      this.updateUserRecommenderObj.recommender = e
    },
    // 打开编辑代理对话框
    openAgencyDialog() {
      this.changeAgencyInfoDialogue = true
      if (this.proxyInfoList.recommenderPhone) {
        this.updateUserRecommenderObj.recommender = this.proxyInfoList.recommender
        this.showUpdateUserRecommenderObj.recommender = this.proxyInfoList.recommenderPhone
        if (this.proxyInfoList.recommenderName && this.proxyInfoList.recommenderName != null) {
          this.showUpdateUserRecommenderObj.recommender = this.proxyInfoList.recommenderPhone + '_' + this.proxyInfoList.recommenderName
        }
      }
    },
    userJumpChange() {
      this.options = []
    },
    // 获取代理可添加人员
    async getAddableUser(keyword, type) {
      const { data: res } = await this.$http.get('/admin/proxy/getAddableUser', { params: { keyword: keyword, type: type } })
      if (res.code == 200) {
        this.options = []
        res.data.forEach((element) => {
          var obj = {}
          obj['label'] = `${element.phone}${element.name == null ? '' : '(' + element.name + ')'}`
          obj['id'] = element.id
          this.options.push(obj)
        })
      }
    },
    // 修改用户推荐人
    async updateUserRecommender() {
      this.updateUserRecommenderObj.phone = this.proxyInfoList.phone
      const { data: res } = await this.$http.post(`/admin/proxy/updateUserRecommender`, this.updateUserRecommenderObj)
      if (res.code == 200) {
        this.$message.success('修改推荐人成功')
        this.getProxyInfo()
        this.changeAgencyInfoDialogue = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 查询客户下单记录
    async getProxyOrderList() {
      const { data: res } = await this.$http.post(`/admin/proxy/getProxyOrderList`, this.getProxyOrderParams)
      this.proxyOrderList = res.data.list
      this.proxyOrderTotal = res.data.total
    },
    // 查询下级代理
    async getProxyTree() {
      const { data: res } = await this.$http.get(`/admin/proxy/getProxyTree?phone=${this.queryParams.phone}`)
      this.proxyTreeList = res.data
    },
    // 获取推荐用户
    async getRecommenderUserList() {
      const { data: res } = await this.$http.get(`/admin/proxy/getRecommenderUserList`, { params: this.getRecommenderUserParams })
      this.recommenderUserList = res.data.list
      this.recommenderUserTotal = res.data.total
    },
    getAuthAttr() {
      var agencyAuth = window.sessionStorage.getItem('auth-14')
      var orderAttr = window.sessionStorage.getItem('auth-3')
      this.agencyAuth = agencyAuth
      this.orderAttr = orderAttr
    },
    async getProxyInfo() {
      const { data: res } = await this.$http.get(`/admin/proxy/getProxyInfo?phone=${this.queryParams.phone}`)
      this.proxyInfoList = res.data
    },
    async getCommissionRecordList() {
      const { data: res } = await this.$http.post('/admin/commission_record/getCommissionRecordList', this.queryParams)
      this.commissionRecordList = res.data.list
      this.total = res.data.total
    },
    // 转账
    changeEft(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.changeCommissionList.type = 2
          this.changeCommissionList.commission = '-' + this.changeCommissionList.commission
          this.changeCommissionList.profit = '-' + this.changeCommissionList.profit
          const { data: res } = await this.$http.post('/admin/commission_record/changeCommission', this.changeCommissionList)
          if (res.code == 200) {
            this.$message.success('转账成功')
            this.getProxyInfo()
            this.getCommissionRecordList()
          } else {
            this.$message.error(res.msg)
          }
          this.changeCommissionList.commission = 0
          this.changeCommissionList.profit = 0
          this.changeEftDialogue = false
        }
      })
    },
    // 变更积金收益
    changeCommission(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.changeCommissionType == '') {
            this.$message.error('请选择类型后再提交')
          } else {
            if (this.changeCommissionType == 2) {
              this.changeCommissionList.commission = '-' + this.changeCommissionList.commission
              this.changeCommissionList.profit = '-' + this.changeCommissionList.profit
            }
            this.changeCommissionList.type = 1
            const { data: res } = await this.$http.post('/admin/commission_record/changeCommission', this.changeCommissionList)
            if (res.code == 200) {
              this.$message.success('变更用户收益成功')
              this.getProxyInfo()
              this.getCommissionRecordList()
            } else {
              this.$message.error(res.msg)
            }
            this.changeCommissionDialogue = false
          }
          this.changeCommissionList.commission = 0
          this.changeCommissionList.profit = 0
          this.changeCommissionList.type = ''
          this.changeCommissionList.remarks = ''
          this.changeCommissionType == ''
        }
      })
    },
    identity(row) {
      if (row.proxyRole == 1) {
        return '市级代理'
      } else if (row.proxyRole == 2) {
        return '区县代理'
      } else if (row.proxyRole == 3) {
        return '个人代理'
      } else if (row.proxyRole == 0) {
        return '特级代理'
      }
    },
    typeFormatter(row) {
      if (row.type == 1) {
        return '订单返利'
      } else if (row.type == 2) {
        return '居间费'
      } else if (row.type == 3) {
        return '后台操作'
      } else if (row.type == 4) {
        return '特级居间费'
      } else if (row.type == 5) {
        return '提现'
      } else if (row.type == 6) {
        return '转账'
      }
    },

    goto(phone) {
      this.$router.push({ path: '/redirect', query: { router: `/userInfo?phone=${phone}&type=${this.type}&tab=5` } })
    },
    gotoOrderld(id) {
      this.$router.push({ path: 'particulars', query: { orderId: id, pageNum: this.queryParams.pageNum, particularsType: 4 } })
    }
  }
}
</script>
<style lang='scss' scoped>
.head {
  display: flex;
  height: 50px;
}
.title {
  margin: auto 0;
}
</style>